import cookie from '../../utils/cookie';

type Consent = Record<LivelyContentful.BlogAndMisc.Fields.CookieType['consentType'], boolean>;
export type ConsentState = Partial<Consent>;
export type ConsentCookie = {
  accepted: boolean;
  consent: ConsentState;
};

class CookiesConsent {
  private static consentState: ConsentState = {};
  private static strictlyNecessaryConsentTypes: ConsentState | null = null;
  private static domain: string | undefined;

  private constructor() {}

  private static areStrictlyNecessaryCookiesDefined(): boolean {
    if (!this.strictlyNecessaryConsentTypes) {
      return false;
    }

    return true;
  }

  public static readonly COOKIE_NAME: string = 'lively_consent';

  public static allAvailaleConsentTypes: LivelyContentful.BlogAndMisc.Fields.CookieType['consentType'][] =
    [];

  /**
   * Sets the consent types that the cookie will have
   * and stores the strictly necessary cookie types in order to make sure
   * that their respective consent types are always set to true.
   */
  public static init(
    cookieTypes: LivelyContentful.BlogAndMisc.Fields.CookieType[],
    domain?: string
  ) {
    const strictlyNecessaryCookieTypes: LivelyContentful.BlogAndMisc.Fields.CookieType[] =
      cookieTypes.filter((cookieType) => cookieType.isStrictlyNecessary) || [];
    const strictlyNecessaryConsentTypesArray: LivelyContentful.BlogAndMisc.Fields.CookieType['consentType'][] =
      strictlyNecessaryCookieTypes.map((cookieType) => cookieType.consentType) || [];
    const strictlyNecessaryConsentTypesObject: ConsentState = Object.fromEntries(
      strictlyNecessaryConsentTypesArray.map((consentType) => [consentType, true])
    );

    this.strictlyNecessaryConsentTypes = strictlyNecessaryConsentTypesObject;

    // Just making sure we don't have duplicated values
    this.allAvailaleConsentTypes = [
      ...new Set(cookieTypes.map((cookieType) => cookieType.consentType))
    ];

    this.setAllConsentTypesAcceptedState();

    if (domain) {
      this.domain = domain;
    }
  }

  public static setAllConsentTypesAcceptedState = () => {
    const newConsentState: ConsentState = Object.fromEntries(
      this.allAvailaleConsentTypes.map((consentType) => [consentType, true])
    );

    this.updateState(newConsentState);
  };

  public static getState(): ConsentState {
    return this.consentState;
  }

  /**
   * Updates the specified consent types except for the strictly necessary ones.
   */
  public static updateState(consentsToUpdate: ConsentState): ConsentState | false {
    if (!this.areStrictlyNecessaryCookiesDefined()) {
      console.error('Cookie types must be set up before starting updating consent');
      return false;
    }

    this.consentState = {
      ...this.getState(),
      ...consentsToUpdate,
      ...this.strictlyNecessaryConsentTypes
    };

    return this.getState();
  }

  public static createCookie(userAccepted: boolean) {
    const today = new Date();

    // Should ask to opt back in after 12 months
    const expires = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds(),
      today.getMilliseconds()
    );

    const livelyConsentCookie: ConsentCookie = {
      accepted: userAccepted,
      consent: this.getState()
    };

    cookie.set(this.COOKIE_NAME, JSON.stringify(livelyConsentCookie), {
      expires,
      path: '/',
      domain: this.domain === 'localhost' ? this.domain : `.${this.domain}`
    });
  }

  public static getCookie() {
    return cookie.get(this.COOKIE_NAME);
  }

  public static deleteAllCookies() {
    cookie.removeAll({
      path: '/',
      domain: this.domain === 'localhost' ? this.domain : `.${this.domain}`
    });
  }
}

export default CookiesConsent;
