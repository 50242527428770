import { FC, Fragment } from 'react'

interface IProps extends LivelyInterfaces.ReactChildren {
    condition: any
}

const RenderOnCondition: FC<IProps> = ({ condition, children }: IProps) => {
    if (condition) {
        return <Fragment> { children } </Fragment>
    }
    return null
}

export default RenderOnCondition
