'use client';
import dynamic from 'next/dynamic';
import RenderOnCondition from '../hocs/RenderOnCondition';
import { FC, useState, useContext, useEffect } from 'react';
import { LivelySettingsContext } from '../context/LivelySettings';
import useLayoutEffect from '../hooks/useIsomorphicLayoutEffect';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import CookiesConsent, { ConsentState, ConsentCookie } from './cookiesConsent';
import cookie from '../utils/cookie';

const Popup = dynamic(() => import('./Popup'));
const Modal = dynamic(() => import('../../Calculators/components/modal/modal'));
const Settings = dynamic(() => import('./Settings'));

const shouldShowPopup = (): boolean => {
  const consent = CookiesConsent.getCookie();

  if (!consent) {
    return true;
  }

  const parsedConsent: ConsentCookie = JSON.parse(consent);

  return !parsedConsent.accepted;
};

const shouldShowSettings = (searchParams: ReadonlyURLSearchParams): boolean =>
  Boolean(searchParams?.get('consent_settings'));

const CookiesPopup: FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { settings } = useContext(LivelySettingsContext);
  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(shouldShowPopup());
  const [settingsIsVisible, setSettingsIsVisible] = useState<boolean>(
    shouldShowSettings(searchParams)
  );
  const [consentState, setConsentState] = useState<ConsentState>();

  const getCleanPath = (): string => pathname;

  const acceptAllCookies = () => {
    CookiesConsent.setAllConsentTypesAcceptedState();
    CookiesConsent.createCookie(true);
    setPopupIsVisible(false);
    setSettingsIsVisible(false);
  };

  const handleClosePopup = () => setPopupIsVisible(false);

  const handleSettingsClick = () => {
    setPopupIsVisible(false);
    setSettingsIsVisible(true);
  };

  const closeSettings = () => {
    CookiesConsent.setAllConsentTypesAcceptedState();
    setSettingsIsVisible(false);
  };

  const handleConsentChange = (
    consentType: LivelyContentful.BlogAndMisc.Fields.CookieType['consentType'],
    value: boolean
  ) => {
    const consentToUpdate: ConsentState = {
      [consentType]: value
    };

    CookiesConsent.updateState(consentToUpdate);
  };

  const handleConfirmSettings = () => {
    // All cookies are deleted if the user sets custom cookies settings
    CookiesConsent.deleteAllCookies();

    CookiesConsent.createCookie(true);
    setSettingsIsVisible(false);
  };

  /**
   * Cookie creation must be performed before the browser paints the page,
   * otherwise, it will take one more page load for GTM to detect the cookie.
   */
  useLayoutEffect(() => {
    if (settings) {
      const domain: string | undefined =
        typeof window !== 'undefined' ? window.location.hostname : undefined;

      CookiesConsent.init(settings.cookieTypes, domain);

      setConsentState(CookiesConsent.getState());

      const consentCookie = CookiesConsent.getCookie();

      if (!consentCookie) {
        CookiesConsent.createCookie(false);
      } else {
        const parsedConsent = JSON.parse(consentCookie);

        // Remove persistent cookies
        settings.cookieTypes.forEach((cookieType) => {
          if (
            cookieType.persistentCookies &&
            parsedConsent?.consent &&
            parsedConsent.consent[cookieType.consentType] !== true
          ) {
            cookieType.persistentCookies.forEach((cookieName) => {
              cookie.hardRemove(cookieName, { path: getCleanPath(), domain });
              cookie.hardRemove(cookieName, { path: '/', domain });
            });
          }
        });
      }
    }
  }, [settings]);

  useEffect(() => {
    setSettingsIsVisible(shouldShowSettings(searchParams));
  }, [searchParams]);

  if (settings) {
    return (
      <>
        <RenderOnCondition condition={popupIsVisible}>
          <Popup
            cookiesAcceptCta={settings.cookiesAcceptCta}
            cookiesPopupText={settings.cookiesPopupText}
            cookiesSettingsCta={settings.cookiesSettingsCta}
            onAcceptClick={acceptAllCookies}
            onClosePopup={handleClosePopup}
            onSettingsClick={handleSettingsClick}
          />
        </RenderOnCondition>

        <RenderOnCondition condition={settingsIsVisible}>
          <Modal
            show={settingsIsVisible}
            handleClose={closeSettings}
            title={settings.cookiesSettingsTitle}
            view={
              <Settings
                privacyText={settings.cookiesSettingsText}
                subtitle={settings.cookiesSettingsSubtitle}
                cookieTypes={settings.cookieTypes}
                strictlyNecessaryText={settings.cookiesStrictlyNecessaryText}
                confirmSettingsCTA={settings.cookiesConfirmSettingsCta}
                onConsentChange={handleConsentChange}
                onConfirmSettings={handleConfirmSettings}
                onAllowAll={acceptAllCookies}
                allowAllCTA={settings.cookiesSettingsAllowAllCta}
                consentState={consentState}
              />
            }
          />
        </RenderOnCondition>
      </>
    );
  }

  return <></>;
};

export default CookiesPopup;
