type CookieUtil = {
  get: GetCookie
  set: SetCookie
  remove: RemoveCookie
  hardRemove: RemoveCookie
  removeAll: RemoveAllCookies
}

type CookieOptions = {
  path?: string
  expires?: string | Date
  domain?: string
}

type GetCookie = (cookieName: string, decoded?: boolean) => string | null
type SetCookie = (cookieName: string, value: string, options?: CookieOptions) => void
type RemoveCookie = (cookieName: string, options?: Omit<CookieOptions, 'expires'>) => void
type RemoveAllCookies = (options?: Omit<CookieOptions, 'expires'>) => void

const expiratedDate: string = new Date(new Date().getTime() - 999999).toUTCString()

const get: GetCookie = (cookieName, decoded = true) => {
  if (typeof window === 'undefined') return ''

  const match: RegExpMatchArray | null  = document.cookie.match(`${cookieName}=.*?(?=;|$)`)
  const value: string | null = match ? ( decoded ? decodeURI(match[0]) : match[0] ).replace(`${cookieName}=`, '') : null

  return value
}

const set: SetCookie = (cookieName, value, options = {}) => {
  const { expires, path, domain } = options

  if (typeof window === 'undefined') return

  let cookie: string = `${cookieName}=${encodeURI(value)};`

  if (expires) {
    const date: Date = typeof expires === 'string' ? new Date(expires) : expires

    cookie += `expires=${date.toUTCString()};`
  }

  if (path) {
    cookie += `path=${path};`
  }

  if (domain) {
    cookie += `domain=${domain};`
  }

  document.cookie = cookie
}

const remove: RemoveCookie = (cookieName, options = {}) => {
  if (typeof window === 'undefined') return

  const { path, domain } = options

  let deletionString = `${cookieName}=bye;expires=${expiratedDate};`

  if (path) {
    deletionString += `path=${path};`
  }

  if (domain) {
    deletionString += `domain=${domain};`
  }

  document.cookie = deletionString
}

/**
 * Unlike `remove`, `hardRemove` tries to delete the cookie with all possible 
 * combinations using the path and domain options
 */
const hardRemove: RemoveCookie = (cookieName, options = {}) => {
  if (typeof window === 'undefined') return

  const { path, domain } = options

  // Remove the cookie from the root directory and the current path
  remove(cookieName)

  if (path) {
    remove(cookieName, { path })
  }

  if (domain) {
    remove(cookieName, { domain })
  }

  if (path && domain) {
    remove(cookieName, { path, domain })
  }
}

/**
 * Removes all cookies created with the provided configuration.
 */
const removeAll: RemoveAllCookies = (options) => {
  if (typeof window === 'undefined') return

  document.cookie.split(';').forEach( cookie => {
    const [ cookieName ] = cookie.split('=')
    
    hardRemove(cookieName, options)
  })
}

const cookie: CookieUtil = {
  get,
  set,
  remove,
  removeAll,
  hardRemove
}

export default cookie